.item-page{
    max-width: 900px;
    margin: auto;
     
    .belegungskalender a{
        padding: 10px;
        color: $hintergrundFarbe;
        background-color: $textFarbe;
        @include rahmen(border);
    }
}