@charset "UTF-8";
html {
  line-height: 1.15; }

body {
  color: #153754;
  background-color: #f4f2ea;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4em;
  overflow-x: hidden; }

/* Modul-Bearbeiten-Button */
body a.btn.jmodedit:before {
  content: "edit";
  color: #fff !important;
  background-color: green !important;
  border: 1px solid black !important;
  padding: 5px !important;
  font-size: 20px !important;
  font-weight: bold !important; }

/* Menü-Bearbeiten-Button */
body a.btn.jfedit-menu:before {
  content: "Inhalt bearbeiten";
  color: #fff !important;
  background-color: green !important;
  border: 1px solid black !important;
  padding: 5px !important;
  font-size: 20px !important;
  font-weight: bold !important; }

a {
  color: #23527c;
  text-decoration: none; }
  a.hover {
    font-weight: bold; }

h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: normal; }
  @media only screen and (min-width: 650px) {
    h1 {
      font-size: 48px; } }

h2 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: normal; }
  @media only screen and (min-width: 650px) {
    h2 {
      font-size: 36px; } }

h3 {
  font-size: 25px; }
  @media only screen and (min-width: 650px) {
    h3 {
      font-size: 30px; } }

h4 {
  font-size: 24px; }

ul {
  list-style-type: none;
  display: block; }

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  font-style: italic;
  border-left: 10px solid #D6E3EC; }

/*
table {

	box-shadow: 10px 10px 10px;

	td{
		padding: 20px 0 20px 10px;
	}
}
*/
header {
  border-bottom: 0px solid #153754; }
  @media only screen and (min-width: 650px) {
    header {
      height: 20px; } }

ul.dj-mobile-light li.dj-mobileitem > a {
  color: #153754; }

button {
  height: 50px;
  width: 200px;
  margin: 20px auto 20px auto;
  box-shadow: -0px 0px 1px;
  background-color: #EDEBEC;
  border: 1px;
  border-color: #153754; }

.blog {
  max-width: 900px;
  margin: auto;
  padding: 0 20px 0 20px; }
  .blog .discstyle li {
    margin-left: 20px; }
  .blog .items-row.cols-1 .introText img {
    max-width: 100%;
    height: auto; }
  .blog .items-row.cols-1last-cild {
    margin-bottom: 100px; }
  @media only screen and (min-width: 900px) {
    .blog {
      padding: 0; } }

footer {
  padding-top: 15px;
  border-top: 1px solid #153754; }

table {
  font-size: 12px;
  word-break: break-word; }
  @media only screen and (min-width: 650px) {
    table {
      font-size: 16px; } }

.top-1 {
  display: inline-flex;
  width: 100%; }
  @media only screen and (min-width: 650px) {
    .top-1 {
      display: block; } }

#toTopBtn {
  box-shadow: none;
  margin: 0;
  display: none;
  position: fixed;
  bottom: 0px;
  width: 50px;
  left: 0px;
  z-index: 9999999;
  font-size: 40px;
  border: none;
  outline: none;
  color: #153754;
  cursor: pointer;
  padding: 1px; }

/*@import './modules/content-blog';Testweise entfernt 290619*/
.item-page {
  max-width: 900px;
  margin: auto; }
  .item-page .belegungskalender a {
    padding: 10px;
    color: #f4f2ea;
    background-color: #153754;
    border: 1px solid #153754;
    border-radius: 10px; }

.menuKIDHeader {
  display: none;
  margin: 0 20px 0 0; }
  @media only screen and (min-width: 650px) {
    .menuKIDHeader {
      display: inline;
      float: right;
      margin-right: 20px; }
      .menuKIDHeader div {
        width: 50%; }
      .menuKIDHeader li {
        display: inline;
        margin-left: 10px; } }

.logo {
  display: none;
  background-color: #D6E3EC;
  color: #153754;
  text-decoration: none; }
  .logo h2 {
    margin: 0;
    padding: 10px 0 10px 0; }
  .logo a:hover {
    text-decoration: none !important; }
  @media only screen and (min-width: 650px) {
    .logo {
      display: block; } }

.logo-mobil {
  width: calc(100% - 50px);
  height: 50px; }
  .logo-mobil h2 {
    margin-top: 5px;
    font-size: 18px; }
  @media only screen and (min-width: 650px) {
    .logo-mobil {
      width: 100%;
      display: none; } }

.contactline {
  /*margin: 100px 0 100px 0; 290619*/
  margin: 0px 0 35px 0;
  background-color: #D6E3EC;
  transition: width 2s;
  display: none; }
  @media only screen and (min-width: 650px) {
    .contactline {
      display: block; } }

.slideshowStart {
  display: none; }
  @media only screen and (min-width: 650px) {
    .slideshowStart {
      display: block; } }

.favsliderpro-carousel .favsliderpro-images.favsliderpro-image-center img {
  width: 100%; }

.favsliderpro-carousel .favth-carousel-inner {
  margin-top: 20px;
  margin-bottom: 0px; }

.favth-row {
  margin-right: -15px;
  margin-left: -15px; }

.wohnungsuebersicht {
  display: none;
  background-image: none;
  background-image: url(/images/wohnungen/hintergrund/Background-small.jpg); }
  .wohnungsuebersicht .wohnungsuebersicht-bilder {
    text-align: center; }
    .wohnungsuebersicht .wohnungsuebersicht-bilder img {
      max-width: 80%; }
  @media only screen and (min-width: 650px) {
    .wohnungsuebersicht {
      display: block;
      background-image: url(/images/wohnungen/hintergrund/DSC00915_ILCE-7M2_1920px_96ppi.jpg); }
      .wohnungsuebersicht img {
        width: 300px; } }
  @media only screen and (min-width: 1500px) {
    .wohnungsuebersicht {
      background-image: url(/images/wohnungen/hintergrund/DSC00939_ILCE-7M2-1980-70.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      /*img{
            width: 300px;
        }*/ }
      .wohnungsuebersicht .wohnungsuebersicht-bilder {
        margin-left: 240px;
        margin-right: 100px;
        padding: 20px 100px 20px 120px; } }
  @media only screen and (min-width: 1921px) {
    .wohnungsuebersicht {
      background-image: url(/images/wohnungen/hintergrund/DSC00939_ILCE-7M2.jpg); } }

.homeWohnungsuebersichtSmall {
  display: block;
  z-index: -1 !important; }
  .homeWohnungsuebersichtSmall img {
    width: 90%; }
  @media only screen and (min-width: 650px) {
    .homeWohnungsuebersichtSmall {
      display: none; } }

.menuKIDVertikal {
  display: block;
  margin: 16px 20px 0 auto; }
  .menuKIDVertikal li {
    display: block;
    text-align: right;
    padding-right: 20px; }

.menuWohnungen {
  background-color: #D6E3EC;
  color: #153754;
  margin: 16px 20px 0 20px;
  display: block; }

.features {
  display: none;
  margin: auto;
  color: #153754 !important; }
  .features .favfeatures-icon-left {
    color: #153754 !important;
    background-color: #f4f2ea !important; }
    .features .favfeatures-icon-left i {
      color: #153754 !important; }
  @media only screen and (min-width: 650px) {
    .features {
      display: block; } }

.featuresMobil {
  color: #153754 !important; }
  .featuresMobil .favth-col-xs-12 {
    width: 50%;
    padding-left: 10px; }
    .featuresMobil .favth-col-xs-12 div {
      color: #153754 !important;
      background-color: #f4f2ea !important;
      min-height: 42px;
      min-width: 42px; }
      .featuresMobil .favth-col-xs-12 div i {
        color: #153754 !important; }
  @media only screen and (min-width: 650px) {
    .featuresMobil {
      display: none; } }

footer {
  display: none; }
  @media only screen and (min-width: 650px) {
    footer {
      background-color: #D6E3EC;
      color: #153754;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr auto 1fr; }
      footer a {
        color: #153754; } }

.bottom-3 .clshomeContact {
  padding-top: 10px;
  font-size: 25px;
  text-align: unset;
  padding-left: calc(50vw - 46px); }

.bottom-3 .closed {
  height: 50px;
  overflow: hidden;
  /* verhindert das scrolling im button*/
  /* completely offscreen */
  -webkit-transform: translate(0, calc(100% - 50px));
  -moz-transform: translate(0, calc(100% - 50px));
  -ms-transform: translate(0, calc(100% - 50px));
  transform: translate(0, calc(100% - 50px)); }
  .bottom-3 .closed .clshomeContact {
    position: sticky;
    top: 0;
    z-index: 999999; }
    .bottom-3 .closed .clshomeContact img {
      z-index: 99999;
      position: relative;
      margin-top: 0;
      float: right;
      padding-right: 15px;
      margin-top: -10px;
      width: 60px; }
  .bottom-3 .closed .clshomeContactClose {
    display: none; }

.bottom-3 .opened {
  height: 100vh;
  -webkit-transform: translate(0, 0%);
  -moz-transform: translate(0, 0%);
  -ms-transform: translate(0, 0%);
  transform: translate(0, 0%); }
  .bottom-3 .opened #btnHomeContact2 {
    padding-top: 60px;
    /*notwendig da das Element sonst unter die Browserzeile rutscht*/ }
  .bottom-3 .opened .clshomeContactClose {
    font-size: 25px;
    bottom: 0; }
  .bottom-3 .opened img {
    z-index: 99999;
    position: relative;
    margin-top: 0;
    float: right;
    width: 100px;
    padding-right: 15px; }

.bottom-3 .moduletable .custom {
  z-index: 999999;
  overflow-y: scroll;
  position: fixed;
  bottom: 0; }

.mobileContactSlide {
  height: 100%;
  position: relative;
  bottom: 0;
  background-color: #EDEBEC;
  -webkit-transition: -webkit-transform .5s ease;
  -moz-transition: -moz-transform .5s ease;
  -ms-transition: -ms-transform .5s ease;
  transition: transform .5s ease; }
  .mobileContactSlide button {
    width: 100%;
    margin: 0; }
  .mobileContactSlide .text-danger li {
    font-size: 12px; }
  @media only screen and (min-width: 650px) {
    .mobileContactSlide {
      display: none; } }

#buttonContactMobile {
  z-index: 9999;
  position: relative;
  max-height: 1000px;
  overflow-y: scroll;
  background-color: #EDEBEC;
  font-size: 16px;
  padding: 20px; }
  #buttonContactMobile button {
    margin-bottom: 0; }
  #buttonContactMobile form {
    border-top: 1px solid #153754; }
  #buttonContactMobile .form-group {
    display: inline-flex; }
  #buttonContactMobile input {
    height: 28px;
    margin-left: 20px;
    margin-top: 5px; }
  #buttonContactMobile label {
    font-weight: normal !important; }
    #buttonContactMobile label:first-child {
      margin-top: 10px; }
  #buttonContactMobile textarea {
    width: 100%;
    height: 30px; }
    #buttonContactMobile textarea:focus {
      height: 100px; }
  #buttonContactMobile .jdscf-col-md-12:nth-of-type(3) .form-group {
    display: block; }
    #buttonContactMobile .jdscf-col-md-12:nth-of-type(3) .form-group .form-group label {
      width: 100%; }

.menu-mobile .dj-mobile-open-btn {
  font-size: 30px;
  margin-top: 5px;
  margin-left: 5px;
  color: #f4f2ea !important;
  background-color: #153754 !important; }

@media only screen and (min-width: 650px) {
  .menu-mobile {
    display: none; } }

.buchungsAnfrage {
  padding: 20px;
  border: 1px solid #153754;
  border-radius: 10px;
  display: none; }
  .buchungsAnfrage .jdscf-row:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-row-gap: 10px; }
  .buchungsAnfrage .jdscf-row .jdscf-col-md-12 {
    height: 60px; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12 .input-group {
      display: inline-block;
      list-style: none; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12 label {
      margin-bottom: 0; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12 .text-danger li {
      font-size: 10px; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12 input {
      border: none;
      background-color: unset;
      border-bottom: 1px solid #153754; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12 button {
      margin: 0; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/1; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/1; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/2; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2/2; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3/3; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3/3; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(7) {
      grid-column: 1/2;
      grid-row: 4/4; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(8) {
      grid-column: 2/3;
      grid-row: 4/4; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(9) {
      grid-column: 3/4;
      grid-row: 1/1; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(10) {
      grid-column: 3/4;
      grid-row: 2/2; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(11) {
      grid-column: 3/4;
      grid-row: 3/3; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(12) {
      grid-column: 3/4;
      grid-row: 4/4; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(13) {
      grid-column: 1/4;
      grid-row: 5/5; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(14) {
      grid-column: 1/4;
      grid-row: 6/6; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(15) {
      height: 80px;
      grid-column: 1/3;
      grid-row: 7/7; }
    .buchungsAnfrage .jdscf-row .jdscf-col-md-12:nth-child(16) {
      grid-column: 3/4;
      grid-row: 7/7; }

.startSeite {
  padding: 20px;
  margin-bottom: 50px;
  z-index: -1 !important; }
  @media only screen and (min-width: 650px) {
    .startSeite {
      max-width: 900px;
      margin: auto; }
      .startSeite .inhalt {
        column-count: 2;
        margin-top: 40px; }
        .startSeite .inhalt p:first-child {
          margin-top: 0; } }

.category-desc {
  margin-top: 50px; }

.kategorieblogStartseite {
  max-width: 900px;
  z-index: -1 !important; }
  .kategorieblogStartseite .items-row {
    display: block; }
    @media only screen and (min-width: 650px) {
      .kategorieblogStartseite .items-row {
        display: flex;
        padding-top: 50px; }
        .kategorieblogStartseite .items-row h4 {
          margin-top: 0; }
        .kategorieblogStartseite .items-row .item {
          display: inline-flex; }
        .kategorieblogStartseite .items-row img {
          margin: 0; } }
    @media only screen and (min-width: 650px) and (min-width: 650px) {
      .kategorieblogStartseite .items-row img {
        margin-right: 50px; } }
    @media only screen and (min-width: 650px) {
        .kategorieblogStartseite .items-row:last-child {
          margin-bottom: 100px; } }

.autor {
  font-size: 12px;
  padding: 10px 20px;
  margin: -20px 0px 0px 0px;
  font-style: italic;
  border-left: 10px solid #D6E3EC; }

.homeHeaderMobil img {
  width: 100%; }

@media only screen and (min-width: 650px) {
  .homeHeaderMobil {
    display: none; } }

/*Kann vermutlich weg*/
.homeContact {
  transform: translate3d(0, 0, 0);
  z-index: 9999 !important;
  height: 50px;
  margin-bottom: 0;
  width: 100%;
  bottom: 0;
  font-size: 1.2em;
  position: fixed; }
  .homeContact img {
    height: 80px;
    width: 80px;
    float: right;
    margin-top: -50px; }
  @media only screen and (min-width: 650px) {
    .homeContact {
      display: none; } }

.wohnungSlideshow {
  display: none; }
  @media only screen and (min-width: 650px) {
    .wohnungSlideshow {
      display: block; } }

@media only screen and (min-width: 650px) {
  .wohnungSlideshowMobil {
    display: none; } }

img.left, .pull-left {
  margin-right: 50px; }

@media only screen and (max-width: 648px) {
  .item-page {
    padding: 0 10px 80px 10px; }
  .pull-left {
    margin: 0; }
  .pull-left img {
    width: 100%; }
  .noMobile {
    display: none; } }
