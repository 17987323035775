.slideshowStart{
   display: none;

    @media #{$tablet} {
		display: block;
	}
}
.favsliderpro-carousel .favsliderpro-images.favsliderpro-image-center img {
  width: 100%;
}

.favsliderpro-carousel .favth-carousel-inner {
  margin-top: 20px;
  margin-bottom: 0px;
}

.favth-row {
  margin-right: -15px;
  margin-left: -15px;
}