.wohnungsuebersicht{

    display: none;
    background-image: none;
    background-image: url(/images/wohnungen/hintergrund/Background-small.jpg);

   .wohnungsuebersicht-bilder{     
        text-align: center; 
        //background-image: url(/images/wohnungen/hintergrund/Background-small.jpg);

        img{
            max-width: 80%;
        }
    }

    @media #{$tablet}{
        display: block;
        background-image: url(/images/wohnungen/hintergrund/DSC00915_ILCE-7M2_1920px_96ppi.jpg);      
        
        img{
            width: 300px;
        }
    }

    @media #{$HDScreen} {
        background-image: url(/images/wohnungen/hintergrund/DSC00939_ILCE-7M2-1980-70.jpg);
    //background-image: url(/images//wohnungen/hintergrund/DSC00939_ILCE-7M2_1920px.jpg);
 
        background-repeat: no-repeat;
        background-size: cover;

        .wohnungsuebersicht-bilder{
            margin-left: 240px;
            margin-right: 100px;
            padding: 20px 100px 20px 120px;
        }
    
        /*img{
            width: 300px;
        }*/
    }

    @media #{$UHDScreen} {
        background-image: url(/images/wohnungen/hintergrund/DSC00939_ILCE-7M2.jpg);
    }

}
.homeWohnungsuebersichtSmall{
    display: block;
    z-index: -1!important;

    img{
        width: 90%;
    }

    @media #{$tablet}{
        display: none;
    }
}