@mixin rahmen($direction: "border", $width: $rahmenStaerke, $radius: $rahmenRadius, $color: $rahmenFarbe) {

	@if $direction == 'border'{
		border: $width solid $color;
		border-radius: $radius;
	}
	@if $direction == 'bottom'{
		border-bottom: $width solid $color;
	}
	@if $direction == 'top'{
		border-top: $width solid $color;
	}
	@if $direction == 'right'{
		border-right: $width solid $color;
	}
	@if $direction == 'left'{
		border-left: $width solid $color;
	}
}

@mixin wz-button($targetDevice){

	@if $targetDevice == 'desktop'{
	
	}


}



