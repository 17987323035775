.menu-mobile{

    .dj-mobile-open-btn{
        font-size: 30px;
        margin-top: 5px;
        margin-left: 5px;
        color: $hintergrundFarbe!important;
        background-color: $textFarbe!important;
    }

    @media #{$tablet} {
        display: none;
    }
}