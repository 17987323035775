.contactline{
    /*margin: 100px 0 100px 0; 290619*/
    margin: 0px 0 35px 0;
    background-color: $hintergrundFarbe2;
	transition: width 2s;
	display: none;


    @media #{$tablet} {
     
        display: block;
    
    }	
}
