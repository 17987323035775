// importiere die SASS Variablen
@import './base/variables';

//importiere die mixins
@import './base/mixins';

// importiere die HTML Basis Tags
@import './base/base';

// importiere das Layout
@import './base/layout';

/*@import './modules/content-blog';Testweise entfernt 290619*/
@import './modules/content-detail';


//Importiere die einzelnen Module
@import './modules/menuKIDHeader';
@import './modules/logo'; 
@import './modules/contactline';
@import './modules/slideshowStart';

@import './modules/wohnungsuebersicht';
@import './modules/menuKIDVertikal';
@import './modules/menuWohnungen'; 
@import './modules/features';
@import './modules/footer';
// Mobiles Kontakt-button - formular
@import './modules/mobileContactSlide';

@import './modules/menu-mobile';

@import './modules/buchungsAnfrage'; 


// Testeinstellungen
@import './modules/test';






