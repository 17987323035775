.features {
    display: none;
    margin: auto;
    //padding: 100px 20px 100px 20px;
    color: $textFarbe!important;

    .favfeatures-icon-left{
        color: $textFarbe!important;
        background-color: $hintergrundFarbe!important;

        i{
            color:$textFarbe!important;
        }
    }
    @media #{$tablet}{
        display: block;
    }
}

.featuresMobil{

    color: $textFarbe!important;

    .favth-col-xs-12 {
        width: 50%;
        padding-left: 10px;

        div{
            color: #153754 !important;
            background-color: #f4f2ea!important;
            min-height: 42px;
            min-width: 42px;

            i{
                color:$textFarbe!important;    
            }
        }
    }
    
    @media #{$tablet}{
        display: none;
    }
}
