.logo{

	display:none;

    background-color: $TS1-nav-unten-bg;
	color: $TS1-nav-unten-color;
	text-decoration: none;
	
	h2{
		margin:0;
		padding: 10px 0 10px 0;
	}

	a{
		&:hover{
		text-decoration: none!important;
		}
	}
	

	@media #{$tablet} {
		display: block;
	}
}

.logo-mobil{

	width: calc(100% - 50px);
	height: 50px;

	h2{
		margin-top: 5px;
		font-size: 18px;
	}

	@media #{$tablet} {
		width: 100%;
		display: none;


	}

}