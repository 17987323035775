//Farben

$textFarbe: #153754;
$linkFarbe:#23527c;
$hintergrundFarbe: #f4f2ea;
$hintergrundFarbe2: #D6E3EC;
$rahmenFarbe: $textFarbe;

//Textgröße mobil
$fontSize: 16px;
$mobilH1:   15px;
$mobilH2:   12px;;


// Teststyle1
$TS1-nav-unten-bg: #D6E3EC;
$TS1-nav-unten-color: $textFarbe;

//Fonts
$fontFamily : 'Open Sans', sans-serif;


//Sonstige Elemente
$rahmenStaerke: 1px;
$rahmenRadius: 10px;


//Media queries --> @media #{$varname} {}
$tablet : 'only screen and (min-width : 650px)';
$Screen : 'only screen and (min-width : 900px)';
$HDScreen: 'only screen and (min-width : 1500px)';
$UHDScreen: 'only screen and (min-width : 1921px)';




