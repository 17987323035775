// Kontakt - Impressum - Datenschutz Menü
.menuKIDHeader{

    display: none;
    margin: 0 20px 0 0 ;
    
    @media #{$tablet} {


        display: inline;
        float: right;
        margin-right: 20px;

        div{
            width: 50%;
        }

        li{
            display: inline;
            margin-left: 10px;
        }



    }
}