// Diese Datei enthält CSS Anweisungen für die entsprechenden Modulpositionen. 
header {
    border-bottom: 0px solid #153754;    
    @media #{$tablet} {
        height: 20px;
    }

}

nav{
}

ul.dj-mobile-light li.dj-mobileitem > a{
    color: $textFarbe;
}

main{
}

button{
    height: 50px;
    width: 200px;
    margin: 20px auto 20px auto;
    box-shadow: -0px 0px 1px;
	background-color: #EDEBEC;
	border: 1px;
    border-color: #153754;
}

.blog{
	max-width: 900px;
    margin: auto;
    padding: 0 20px 0 20px;

    .discstyle li{
        margin-left: 20px;
    }
    
    .items-row.cols-1{

        .introText{
            img{
                max-width: 100%;
                height: auto;
            }
        }

        &last-cild{
            margin-bottom: 100px;
        }
    }

    @media #{$Screen} {
		padding: 0;
	}
}

footer {

    //display:none;
    // text-align: right;
    //@media #{$tablet}{
    //    display:block;
        padding-top: 15px;
        @include rahmen(top);
   // }

}
table{
    font-size: 12px;
    word-break: break-word;

    @media #{$tablet} {
        font-size: 16px;
    }
}
.top-1{

        display: inline-flex;
        width: 100%;

        @media #{$tablet} {
            display: block;
        }


}

#toTopBtn {
    box-shadow: none;
    margin: 0;
    display: none;
    position: fixed;
    bottom: 0px;
    width: 50px;
    left: 0px;
    z-index: 9999999;
    font-size: 40px;
    border: none;
    outline: none;
    color:#153754;
    cursor: pointer;
    padding: 1px;
    
  }
