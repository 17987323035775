.buchungsAnfrage { 
  
  padding: 20px;
  @include rahmen(border);
  display: none;

  .jdscf-row{

     &:first-child{
      display: grid;
      grid-template-columns:1fr 1fr 1fr; 
      grid-template-rows:1fr 1fr 1fr 1fr 1fr 1fr; 
      grid-auto-flow: column; 
      grid-row-gap: 10px;
     }


      
      .jdscf-col-md-12{

        height: 60px;

        .input-group{
          display: inline-block;
          list-style: none;
        }

        label{
          margin-bottom: 0;
        }

        .text-danger li {
          font-size: 10px;
        }

        input{
          border: none;
          background-color: unset;
          @include rahmen(bottom);
        }

        button{
          margin: 0;
        }

        // Vorname
        &:nth-child(1){
          grid-column: 1/2;
          grid-row: 1/1;
        }
        // Nachname
        &:nth-child(2){
          grid-column: 2/3;
          grid-row: 1/1;
        }

        //Strasse
        &:nth-child(3){
          grid-column: 1/2;
          grid-row: 2/2;
        }

        //Hausnummer
        &:nth-child(4){
          grid-column: 2/3;
           grid-row: 2/2;
        }

        // PLZ
        &:nth-child(5){
          grid-column: 1/2;
          grid-row: 3/3;
        }
        
        // Ort
        &:nth-child(6){
          grid-column: 2/3;
          grid-row: 3/3;
        }

        // EMail
        &:nth-child(7){
          grid-column: 1/2;
          grid-row: 4/4;
        }

        // Mobil
        &:nth-child(8){
          grid-column: 2/3;
          grid-row: 4/4;
        }

        //Anreise
        &:nth-child(9){
          grid-column: 3/4;
          grid-row: 1/1;
        }

        // Abreise
        &:nth-child(10){
          grid-column: 3/4;
          grid-row: 2/2;
        }

        //Erwachsene
        &:nth-child(11){
          grid-column: 3/4;
          grid-row: 3/3;
        }

        // Kinder
        &:nth-child(12){
          grid-column: 3/4;
          grid-row: 4/4;
        }

        // AGB
        &:nth-child(13){
          grid-column: 1/4;
          grid-row: 5/5;
        }

        // DGSVO
        &:nth-child(14){
          grid-column: 1/4;
          grid-row: 6/6;
        }

        // Captcha
        &:nth-child(15){
          height: 80px;
          grid-column: 1/3;
          grid-row: 7/7;
        }

        // Absenden
        &:nth-child(16){
          grid-column: 3/4;
          grid-row: 7/7;
        }

      }


  
  }
}