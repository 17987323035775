footer{

    display: none;

    @media #{$tablet} {
	
        background-color: $TS1-nav-unten-bg;
        color: $TS1-nav-unten-color;
        display:grid;
        width:100%;
        grid-template-columns: 1fr auto 1fr;

        a{
            color: $TS1-nav-unten-color;  
        }
    }

}