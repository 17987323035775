html{
	line-height: 1.15;	
}
body {
	color: $textFarbe;
	background-color: $hintergrundFarbe;
	font-family: $fontFamily;
	font-size: 16px;
	line-height: 1.4em;
	overflow-x: hidden;
}

/* Modul-Bearbeiten-Button */

body a.btn.jmodedit:before{

	content:"edit";
	
	color:#fff !important;
	
	background-color:green !important;
	
	border: 1px solid black !important;
	
	padding:5px !important;
	
	font-size:20px !important;
	
	font-weight:bold !important;
	
	}
	
/* Menü-Bearbeiten-Button */

body a.btn.jfedit-menu:before{

	content:"Inhalt bearbeiten";
	
	color:#fff !important;
	
	background-color:green !important;
	
	border: 1px solid black !important;
	
	padding:5px !important;
	
	font-size:20px !important;
	
	font-weight:bold !important;
	
	}

a{
	color:$linkFarbe;
	text-decoration: none;

	&.hover{
		font-weight: bold;
	}
}

h1 {
	font-size: 30px;
	text-transform: uppercase;
	font-weight: normal;
	@media #{$tablet} {
		font-size: 48px;
	}
}

 h2{
	font-size: 25px;
	@media #{$tablet} {
		font-size: 36px;
	}
	 text-transform: uppercase;
    font-weight: normal;
 }

 h3{
	font-size: 25px;

	@media #{$tablet} {
		font-size: 30px;
	}
 }

 h4{
	 font-size: 24px;
 }


ul {
	list-style-type: none;
	//display: inline;
	display:block;
}

blockquote{
	padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
	font-style: italic;
    border-left: 10px solid #D6E3EC;
}




/*
table {

	box-shadow: 10px 10px 10px;

	td{
		padding: 20px 0 20px 10px;
	}
}
*/