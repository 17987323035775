.startSeite{

    padding: 20px;
    margin-bottom: 50px;
    z-index: -1!important;

    @media #{$tablet}{
    max-width: 900px;
    margin: auto;

    .inhalt{
        column-count: 2;
        margin-top: 40px;

        p{
            &:first-child{
                margin-top: 0;
            }
        }
    }
    }
}

.category-desc {
    margin-top: 50px;
}

.kategorieblogStartseite{
    max-width: 900px;
    z-index: -1!important;
    
    .items-row{
        display: block;
        //height: 300px;
        @media #{$tablet} {
        display: flex;
        padding-top: 50px;

        h4{
            margin-top: 0;
        }

        .item{
            display: inline-flex;
        }

       
        img{ 
                
            margin: 0;
                
            @media #{$tablet}{
                margin-right: 50px;
            }
        }

        &:last-child{
            margin-bottom: 100px;
        }
    }
    }
}

.autor{
    font-size: 12px;
    padding: 10px 20px;
    margin: -20px 0px 0px 0px;
    font-style: italic;
    border-left: 10px solid #D6E3EC;
}

.homeHeaderMobil{
    img{
        width:100%;
    }

    @media #{$tablet}{ 
        display:none;
    }
}

/*Kann vermutlich weg*/
.homeContact{
    transform: translate3d(0, 0, 0);
    z-index: 9999!important;
    height: 50px;
    margin-bottom: 0;
    width: 100%;
    bottom: 0;
    font-size: 1.2em;
    position: fixed;

    img{
        height: 80px;
        width: 80px;
        float: right;
        margin-top: -50px;    
    }

    @media #{$tablet}{
        display: none;
    }
}

.wohnungSlideshow{

    display: none;

    @media #{$tablet}{
        display: block;
    }

}
.wohnungSlideshowMobil{
   
    @media #{$tablet}{
        display: none;
    }

}

img.left, .pull-left {
    margin-right: 50px;
}


@media only screen and (max-width: 648px) {

    .item-page{
        padding: 0 10px 80px 10px;
    }    

    .pull-left{
        margin: 0;
    }
    .pull-left img{
        width: 100%;
    }

    .noMobile{display:none;}
}
