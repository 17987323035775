.bottom-3{ 

    .clshomeContact{
        padding-top: 10px;
        font-size: 25px;
        text-align: unset;
        padding-left: calc(50vw - 46px);
    }

    .closed{

        height: 50px;
        overflow: hidden; /* verhindert das scrolling im button*/
          /* completely offscreen */
        
          -webkit-transform: translate(0, calc(100% - 50px));
             -moz-transform: translate(0, calc(100% - 50px));
              -ms-transform: translate(0, calc(100% - 50px));
                  transform: translate(0, calc(100% - 50px));

        .clshomeContact{

            position: sticky;
            top: 0;
            z-index: 999999;

            img{

                z-index: 99999;
                position:relative;
                margin-top: 0;
                float:right;
                padding-right: 15px;    
                
                margin-top: -10px;
                width: 60px;    
            }
        }
        .clshomeContactClose {
            display: none;
        }
    }

    .opened{

        height:100vh;
          -webkit-transform: translate(0, 0%);
             -moz-transform: translate(0, 0%);
              -ms-transform: translate(0, 0%);
                  transform: translate(0, 0%);

        #btnHomeContact2{
            padding-top: 60px; /*notwendig da das Element sonst unter die Browserzeile rutscht*/               
        }

        .clshomeContactClose{
            font-size:25px;
            bottom:0;
          }

        img{
            z-index: 99999;
            position: relative;
            margin-top: 0;
            float: right;
            width: 100px;
            padding-right: 15px;
        }
    }

    .moduletable{ 

        .custom{
            z-index: 999999;
            overflow-y: scroll;
            position: fixed;
            bottom: 0;
        }
    }
}

.mobileContactSlide{
    height:100%;
    position: relative;
    bottom: 0;
    background-color:#EDEBEC;
    -webkit-transition:-webkit-transform .5s ease;
    -moz-transition:   -moz-transform .5s ease;
     -ms-transition:    -ms-transform .5s ease;
         transition:        transform .5s ease; 

    button {
        width: 100%;
        margin:0;
      }

    .text-danger li{
        font-size: 12px;
    }

    @media #{$tablet} {
		display: none;
	}
}

#buttonContactMobile{
    z-index: 9999;  
    position: relative;
    max-height: 1000px;
    overflow-y: scroll;
    background-color: #EDEBEC;
    font-size: 16px;
    padding: 20px;

    button{
        margin-bottom: 0;
    }

    form{
        border-top: 1px solid #153754;
    }

    .form-group{
        display: inline-flex;
    }
    
    input{
        height: 28px;
        margin-left: 20px;
        margin-top: 5px;
    }

    label{
        font-weight: normal!important;
        
        &:first-child{
            margin-top: 10px;
        }
    }

    textarea{
        width: 100%;
        height:30px;

        &:focus{
            height: 100px;
        }
    
    }

    .jdscf-col-md-12:nth-of-type(3) .form-group{
    
        display: block;

        .form-group label{
            width: 100%;
        }
    }







}

